import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/config/store";
import { CSSTransition } from "react-transition-group";
import { raw } from "../../utils/textUtils";
import LightningIcon from "../icons/LightningIcon";
import useAnimationFrame from "../../hooks/useAnimationFrame";
import { setFactoid } from "../../redux/game";

export default function Factoid() {
	const factoid = useAppSelector(state => state.game.factoid);
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);

	useAnimationFrame(() => {
		if (factoid && factoid.expiration < Date.now()) {
			setVisible(false);
		}
	});

	useEffect(() => {
		if (factoid) {
			setVisible(true);
		}
	}, [dispatch, factoid]);

	return factoid && (
		<CSSTransition key={factoid.text} in={visible} timeout={{ enter: 300, appear: 300, exit: 300 }} onExited={() => dispatch(setFactoid(null))} appear mountOnEnter unmountOnExit>
			<div className="poppable factoid-toast">
				<div className="wrapper">
					<div className="border">
						<div className="factoid">
							{factoid.image && <div className="left">
								<div className="image">
									<img src={factoid.image} alt="" />
								</div>
							</div>}
							<div className="right">
								<div className="sponsor">
									<div className="icon-ctn">
										<LightningIcon />
									</div>
									<div className="sponsor-text"><strong>FUN FACT</strong> {factoid.sponsor && <>brought to you by <span className="name">{factoid.sponsor}</span></>}</div>
								</div>
								<div className="text" {...raw(factoid.text)} />
							</div>
						</div>
					</div>
				</div>
				<div className="bottom"></div>
			</div>
		</CSSTransition>
	);
}