import React, { memo, useEffect, useRef } from 'react';
import { CDN_BASE, COUNTDOWN_DURATION, FACTOID_DURATION } from '../../Constants';
import Video from '../ui/Video';
import useAnimationFrame from '../../hooks/useAnimationFrame';
import { useCues } from '../../selectors/useCues';
import { useAppDispatch, useAppSelector } from '../../redux/config/store';
import { setCountdown, setCountdownText, setFactoid, setResetVideo } from '../../redux/game';
import { executeCloudScript } from '../../redux/playfab';

function Home() {
	const videoTimestamp = useRef<HTMLDivElement>(null);
	const video = useRef<HTMLVideoElement>(null);

	const cues = useCues();
	const lastCue = useRef<string>(null);
	const dispatch = useAppDispatch();
	const resetVideo = useAppSelector(state => state.game.resetVideo);
	const resetTime = 23;

	useAnimationFrame(() => {
		const time = new Date(video.current!.currentTime * 1000).toISOString().substring(11, 22);
		videoTimestamp.current!.innerText = time;

		if (cues.length > 0 && video.current) {
			const currentCue = cues.find(cue => {
				return video.current.currentTime - parseFloat(cue.data.timecode as string) <= 2 && video.current.currentTime - parseFloat(cue.data.timecode as string) >= 0;
			});

			if (currentCue && currentCue.itemId !== lastCue.current) {
				lastCue.current = currentCue.itemId;
				console.log('Cue:', currentCue.itemId, currentCue.playfab.DisplayName);

				if (currentCue.data.type === 'countdown') {
					dispatch(setCountdown(Date.now() + COUNTDOWN_DURATION));
					dispatch(setCountdownText(currentCue.data.text as string));

					fetch('https://fa-nab-demo-app.azurewebsites.net/api/StartCountdown', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Access-Control-Allow-Origin': '*',
						},
						mode: 'cors',
						body: JSON.stringify({
							timestamp: Date.now() + COUNTDOWN_DURATION,
						}),
					});
				}

				if (currentCue.data.type === 'quiz') {
					dispatch(executeCloudScript({
						functionName: 'StartQuiz',
						data: {
							quizId: currentCue.data.quizId,
							quizType: currentCue.data.quizType,
						}
					}));
				}

				if (currentCue.data.type === 'factoid') {
					dispatch(setFactoid({
						expiration: Date.now() + FACTOID_DURATION,
						image: currentCue.data.image as string,
						sponsor: currentCue.data.sponsor as string,
						text: currentCue.data.text as string,
					}));
				}

				if (currentCue.data.type === 'changeShow') {
					dispatch(executeCloudScript({
						functionName: 'ShowChange',
						data: {
							currentShow: currentCue.data.currentShow,
						}
					}));
				}
			}
		}
	});

	useEffect(() => {
		if (video.current && resetVideo) {
			video.current.currentTime = resetTime;
			dispatch(setResetVideo(false));
		}
	}, [dispatch, resetVideo]);

	return (
		<div className="page home">
			{/* <Button onClick={() => video.current.currentTime = resetTime} label='Test' /> */}
			<div className="counter" ref={videoTimestamp} style={{ display: 'none' }}></div>
			<Video play={true} className="background" src={CDN_BASE + 'nab-2024-new-bg.mp4'} muted={true} loop nodeRef={video} />
		</div>
	);
}

export default memo(Home);
