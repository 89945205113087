import React from "react";

function LightningIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="96" height="112" viewBox="0 0 96 112" fill="none">
			<path fill="var(--color-background, #fff)" d="M50.954.076v77.787H4L50.954.076z"></path>
			<path fill="var(--color-background, #fff)" d="M49.046 111.033V33.246H96l-46.954 77.787z"></path>
			<path fill="var(--color-foreground, #FF4E02)" d="M46.954.076v77.787H0L46.954.076z"></path>
			<path fill="var(--color-foreground, #FF4E02)" d="M45.046 111.033V33.246H92l-46.954 77.787z"></path>
		</svg>
	);
}

export default LightningIcon;