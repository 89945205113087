import { createSelector } from "@reduxjs/toolkit";
import { ITEM_CLASSES } from "../Constants";
import { IRootState, useAppSelector } from "../redux/config/store";

const cuesSelector = createSelector([
	(state:IRootState) => state.catalog.items,
], (items) => {
	const cues = items.filter(item => item.playfab.ItemClass === ITEM_CLASSES.CUE);

	cues.sort((a, b) => {
		return parseFloat(a.data.timecode as string) - parseFloat(b.data.timecode as string);
	});

	return cues;
});

export function useCues() {
	const cues = useAppSelector(cuesSelector);
	return cues;
}