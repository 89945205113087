import useAnimationFrame from "../../hooks/useAnimationFrame"
import React, { useRef, useState } from "react";
import { useAppSelector } from "../../redux/config/store";
import { CSSTransition } from "react-transition-group";
import { constrain } from "../../utils/constrain";
import CircleProgress from "./CircleProgress";
import { raw } from "../../utils/textUtils";
import { COUNTDOWN_DURATION } from "../../Constants";

export default function Countdown() {
	const {
		countdown: targetTime,
		countdownText,
	} = useAppSelector(state => state.game);
	const counter = useRef<HTMLDivElement>(null);
	const [visible, setVisible] = useState(false);
	const [percent, setPercent] = useState(0);

	useAnimationFrame(() => {
		const time = Math.ceil((targetTime - Date.now()) / 1000);

		if (time < 0 && visible) setVisible(false);
		else if (time >= 0 && !visible) setVisible(true);

		if (counter.current) {
			counter.current!.innerText = constrain(time, 0, COUNTDOWN_DURATION).toString();
			const percent = constrain((targetTime - Date.now()) / COUNTDOWN_DURATION, 0, 1);
			setPercent(percent);
		}
	});

	return (
		<CSSTransition in={visible} timeout={{ enter: 300, appear: 300, exit: 300 }} appear mountOnEnter unmountOnExit>
			<div className="poppable countdown-toast">
				<div className="wrapper">
					<div className="border">
						<div className="countdown">
							<CircleProgress progress={percent} />
							<div className="num" ref={counter}>5</div>
						</div>
						<div className="text" {...raw(countdownText)} />
					</div>
				</div>
				<div className="bottom"></div>
			</div>
		</CSSTransition>
	)
}