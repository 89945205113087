import React, { useLayoutEffect, useEffect, memo } from 'react';

type VideoProps = {
	src: string,
	play:boolean,
	loop?: boolean,
	muted?: boolean,
	onEnded?: () => void,
	className?:string,
	onVideoPlaybackError?: () => void,
	nodeRef?: React.RefObject<HTMLVideoElement>,
};

function Video({ src, muted = true, play, onEnded = () => {}, className = '', onVideoPlaybackError, loop = false, nodeRef }:VideoProps) {
	useLayoutEffect(() => {
		if (!nodeRef?.current) return;
		
		nodeRef.current.currentTime = 0;

		if (play && nodeRef.current.paused) {
			nodeRef.current.play().catch((e) => {
				onVideoPlaybackError?.();
			});
		} else if (!play && !nodeRef.current.paused) {
			nodeRef.current.pause();
		}
	}, [nodeRef, onVideoPlaybackError, play, src]);

	useEffect(() => {
		if (nodeRef?.current) {
			nodeRef.current.currentTime = 0;
			// ref.current.playbackRate = 0.5;
		}
	}, [nodeRef]);

	return (
		<video className={`${className} ${play ? 'playing' : ''}`} ref={nodeRef} src={src} preload='preload' loop={loop} muted={muted} playsInline onEnded={onEnded} disablePictureInPicture />
	);
}

export default memo(Video);