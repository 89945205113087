import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const game = createSlice({
	name: 'game',
	initialState: {
		countdown: 0,
		countdownText: '',
		factoid: null as {
			text: string,
			image: string,
			sponsor: string,
			expiration: number,
		},
		resetVideo: false,
	},
	reducers: {
		setCountdown: (state, action:PayloadAction<number>) => {
			state.countdown = action.payload;
		},
		setCountdownText: (state, action:PayloadAction<string>) => {
			state.countdownText = action.payload;
		},
		setFactoid: (state, action:PayloadAction<{
			text: string,
			image: string,
			sponsor: string,
			expiration: number,
		}>) => {
			state.factoid = action.payload;
		},
		setResetVideo: (state, action:PayloadAction<boolean>) => {
			state.resetVideo = action.payload;
		}
	},
});

export default game;

export const { setCountdown, setCountdownText, setFactoid, setResetVideo } = game.actions;