import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { LoginMethods, setIsLoggingIn, setLoggingMethod } from '../redux/env';
import { addGenericId, authPlayfab } from '../redux/playfab';
import { guid } from '../utils/guid';

const GENERIC_ID_SERVICE_NAME = 'demo_app';

export default function useLogin() {
	const dispatch = useAppDispatch();
	const { isLoggingIn, loggingMethod } = useAppSelector(state => state.env);

	async function auth(str:string, method:LoginMethods) {
		dispatch(setIsLoggingIn(true));
		dispatch(setLoggingMethod(method));
		await dispatch(authPlayfab({ userId: str })).then(() => {
			dispatch(setIsLoggingIn(false));
			dispatch(setLoggingMethod(null));
			dispatch(addGenericId({
				UserId: str,
				ServiceName: GENERIC_ID_SERVICE_NAME,
			}));
		});
	}

	function OnClickLoginWithUser(customId:string) {
		auth(customId, LoginMethods.CUSTOM_ID);
	}

	function OnClickNewUser() {
		auth(guid(), LoginMethods.CUSTOM_ID);
	}

	return {
		loggingMethod,
		IsLoggingIn: isLoggingIn,
		serviceName: GENERIC_ID_SERVICE_NAME,

		OnClickLoginWithUser,
		OnClickNewUser,
	};
}